import { NgModule } from '@angular/core';
import { EmailSignatureComponent } from './components/email-signature/email-signature.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [EmailSignatureComponent],
  imports: [
    SharedModule
  ]
})
export class EmailSignatureModule { }
