import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../home/components/home/home.component';
import { EmailSignatureComponent } from '../email-signature/components/email-signature/email-signature.component';
import { MeetingsComponent } from '../meetings/components/meetings/meetings.component';
import { LoginComponent } from '../authentication/components/login/login.component';
import { AuthGuardService } from '../authentication/services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'reunioes',
    loadChildren: () => import('../meetings/meetings.module').then(m => m.MeetingsModule),
  },
  {
    path: 'indicacoes',
    loadChildren: () => import('../referral/referral.module').then(m => m.ReferralModule),
  },
  {
    path: 'assinatura-de-email',
    component: EmailSignatureComponent,
  },
  {
    path: 'whatsapp',
    loadChildren: () => import('../whatsapp/whatsapp.module').then(m => m.WhatsappModule),
    canActivate: [AuthGuardService],
  }
];

@NgModule({
  declarations: [],
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
