import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { IUser } from '../../../../shared/models/interfaces/users.interface';
import { AuthService } from '../../../authentication/services/auth.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  public userInfo!: IUser | null;

  constructor(private _authService: AuthService, private _router: Router) {
    this.userInfo = _authService.currentUserValue();
  }

  public logout(): void {
    this._authService.logout();
    this._router.navigate(['/login']);
  }
}
