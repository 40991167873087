<!-- Header component -->
 <div class="header-container">
  <app-header></app-header>
 </div>

<div class="container-fluid app-container">
  <div class="row h-100">
    <div class="main-menu bg-light">
      <ul class="nav mt-n2">
        <li class="nav-item my-2">
          <a class="nav-link rounded-pill" title="Ferramentas para RDs" routerLink="/reunioes" routerLinkActive="active"><i class="fa-duotone fa-solid fa-calendar-days"></i></a>
        </li>
        <li class="nav-item my-2">
          <a class="nav-link rounded-pill" title="Indicações e novos negócios" routerLink="/indicacoes" routerLinkActive="active"><i class="fa-duotone fa-solid fa-user-plus"></i></a>
        </li>
        <li class="nav-item my-2">
          <a class="nav-link rounded-pill" title="Gerador de assinatura de e-mail" routerLink="/assinatura-de-email" routerLinkActive="active"><i class="fa-duotone fa-solid fa-envelope"></i></a>
        </li>
        <li class="nav-item my-2 position-relative">
          <span class="position-absolute px-2 py-1 top-100 start-50 translate-middle badge rounded-pill bg-pink">Breve</span>
          <a class="nav-link rounded-pill" title="Enviar mensagens de Whatsapp" routerLink="/whatsapp" routerLinkActive="active"><i class="fa-brands fa-whatsapp"></i></a>
        </li>
      </ul>
    </div>
    <div class="col-md ps-0 pe-0 h-100 position-relative">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
