<div class="container-fluid">
  <div class="row">
    <!-- Formulário -->
    <div class="col-md-8 ps-0 inner-shadow mt-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <h3 class="text-primary">Assinatura de e-mail</h3>
            <p class="text-muted">Gere aqui a assinatura que poderá servir ao Hubspot e ao Gmail.</p>
            
            <form [formGroup]="emailSignatureForm">
              <div class="form-row row g-3">
                <div class="form-group col-md-6">
                  <label class="form-label" for="inputName">Nome</label>
                  <input type="text" class="form-control" required class="form-control" id="inputName" placeholder="Seu nome" formControlName="name">
                </div>
                <div class="form-group col-md-6">
                  <label class="form-label" for="inputRole">Cargo</label>
                  <input type="text" class="form-control" required class="form-control" id="inputRole" placeholder="Seu cargo" formControlName="role">
                </div>
                <div class="form-group col-md-12">
                  <label class="form-label" for="inputPhoto">Sua foto</label>
                  <input type="file" class="form-control" required (change)="onPhotoChange($event)" class="form-control" id="inputPhoto">
                </div>
              </div>

              <!-- Botões: copiar para Hubspot e Copiar para Gmail -->
              <div class="form-row row g-3 mt-3">
                <div class="form-group col text-end">
                  <button type="button" [disabled]="!emailSignatureForm.valid" (click)="copySignatureGmail()" class="btn btn-link"><i class="fa-duotone fa-solid fa-envelope"></i> Copiar para Gmail</button>
                  <button type="button" [disabled]="!emailSignatureForm.valid" (click)="copySignatureHubspot()" class="btn btn-link"><i class="fa-brands fa-hubspot"></i> Copiar para Hubspot</button>
                </div>
              </div>
            </form>
          </div>
          
          <!-- Exemplo -->
          <div class="col-md-6">
            <div class="signature-placeholder-container">
              <div class="signature-container">
                <table #emailSignature cellspacing="0" cellpadding="0" border="0" style="font-family:Arial, Oxygen, sans-serif; font-size: 14px; padding: 0px; margin: 0px;">
                  <tbody>
                    <tr>
                      <td colspan="2"><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <img [src]="photoPreview" style="border-radius: 100%; width:75px; height: 75px;" width="75" height="75">
                      </td>
                      <td valign="top">
                        <table border="0"  cellspacing="0" cellpadding="2" >
                          <tbody>
                            <tr>
                              <td width="15">&nbsp;</td>
                              <td colspan="5" style="padding: 0px; margin: 0px;">
                                <span style="font-size: 16px; color: #0072CE;"><b style="font-weight: 700;">{{emailSignatureForm.value.name || 'Seu nome'}}</b></span>
                              </td>
                            </tr>
                            <tr>
                              <td width="15">&nbsp;</td>
                              <td colspan="5" style="padding: 0px; margin: 0px;">
                                <span style="font-size: 14px; color: #102C6B; font-weight: 400;">{{emailSignatureForm.value.role || 'Seu cargo'}}</span>
                              </td>
                            </tr>
                            <tr>
                              <td width="15">&nbsp;</td>
                              <td style="padding: 0px; margin: 0px;">
                                <a href="https://www.nibo.com.br" title="Site do Nibo" style="margin: 0px;"><img src="https://cdn.nibo.com.br/brand/logo/nb-logo-txt-azpet.png" alt="Nibo logo" height="18" /></a>
                              </td>
                              <td width="6px">&nbsp;</td>
                              <td style="padding: 0px; margin: 0px;">
                                <a href="https://www.instagram.com/nibosoftware/" title="Abrir Instagram" style="margin: 0px;"><img src="https://cdn.nibo.com.br/brand/email-signature/nb-icon-instagram-branco-azpet.png" alt="Instagram" width="18" height="18" /></a>
                              </td>
                              <td width="6px">&nbsp;</td>
                              <td style="padding: 0px; margin: 0px;">
                                <a href="https://www.youtube.com/@NiboSoftware" title="Abrir Youtube" style="margin: 0px;"><img src="https://cdn.nibo.com.br/brand/email-signature/nb-icon-youtube-branco-azpet.png" alt="Youtube" width="18" height="18" /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tutorial -->
    <div class="help-container pt-3 col-md-4 bg-light">
      <ul class="nav nav-tabs w-100" ngbNav #nav="ngbNav">
        <!-- Gmail -->
        <li class="nav-item" ngbNavItem="1">
          <a href="#" class="nav-link" ngbNavLink><i class="fa-duotone fa-solid fa-envelope"></i> Gmail</a>
          <ng-template ngbNavContent>
            <!-- Passo 01 -->
            <div class="d-flex flex-row">
              <div class="stepper me-3">
                <span class="number-circle">1</span>
              </div>
              <div class="pt-2">
                <p>No canto superior direito, clique em Configurações <i class="fa-solid fa-gear text-muted ms-1"></i> <i class="fa-solid fa-chevron-right text-muted ms-1 me-1"></i> <strong>Mostrar todas as configurações</strong>.</p>
                <p><img class="w-100 rounded" src="../../../assets/images/email-signature-gmail-01.jpg" /></p>
              </div>
            </div>

            <!-- Passo 02 -->
            <div class="d-flex flex-row">
              <div class="stepper me-3">
                <span class="number-circle">2</span>
              </div>
              <div class="pt-2">
                <p>Na seção <strong>Assinatura</strong>, cole o conteúdo da sua assinatura à caixa.</p>
                <p><img class="w-100 rounded" src="../../../assets/images/email-signature-gmail-02.gif" /></p>
              </div>
            </div>

            <!-- Passo 03 -->
            <div class="d-flex flex-row">
              <div class="stepper me-3">
                <span class="number-circle">3</span>
              </div>
              <div class="pt-2">
                <p>Na parte inferior da página, clique em <strong>Salvar alterações</strong>.</p>
                <p><img class="w-100 rounded" src="../../../assets/images/email-signature-gmail-03.jpg" /></p>
              </div>
            </div>
            
          </ng-template>
        </li>

        <!-- Hubspot -->
        <li class="nav-item" ngbNavItem="2">
          <a href="#" class="nav-link" ngbNavLink><i class="fa-brands fa-hubspot"></i> Hubspot</a>
          <ng-template ngbNavContent>
            <!-- Passo 01 -->
            <div class="d-flex flex-row">
              <div class="stepper me-3">
                <span class="number-circle">1</span>
              </div>
              <div class="pt-2">
                <p>Na sua conta no Hubspot, clique no ícone configurações <i class="fa-solid fa-gear text-muted ms-1"></i> <i class="fa-solid fa-chevron-right text-muted ms-1 me-1"></i> na barra de navegação. Então navegue para <strong>Geral</strong> e clique na aba <strong>E-mail</strong> e, por fim, procure o link <strong>Editar assinatura de email</strong>.</p>
                <p><img class="w-100 rounded" src="../../../assets/images/email-signature-hubspot-01.jpg" /></p>
              </div>
            </div>

            <!-- Passo 02 -->
            <div class="d-flex flex-row">
              <div class="stepper me-3">
                <span class="number-circle">2</span>
              </div>
              <div class="pt-2">
                <p>Na tela que se abrirá, clique no botão <strong>HTML</strong>, cole o conteúdo da assinatura. Então clique em <strong>Salvar</strong>.</p>
                <p><img class="w-100 rounded" src="../../../assets/images/email-signature-hubspot-02.gif" /></p>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>

      <div class="horizontal-scroll mt-3 me-n3 pe-3" [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
