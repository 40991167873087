<div class="container-fluid home-container bg-light d-flex flex-column justify-content-center align-items-center py-5">
  <div class="home-header">
    <h1 class="text-center text-primary">Olá!</h1>
    <p class="lead">Aqui você pode gerenciar suas oportunidades de vendas, contratos e clientes</p>
  </div>

  <div class="cards mt-3 d-flex justify-content-center align-items-center">
    <div class="card position-relative shadow-sm">
      <div class="card-body">
        <p class="text-center"><i class="display-4 text-secondary"><i class="fa-duotone fa-solid fa-file-invoice"></i></i></p>
        <h5 class="text-center text-secondary"><a href="https://contratos.nibo.com.br" class="stretched-link" target="_blank">Gerador de contratos</a></h5>
        <p class="text-center">Ferramenta para gerar contratos em formato PDF para oportunidades de vendas</p>
      </div>
    </div>
  </div>
</div>
