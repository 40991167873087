import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login'], { queryParams: { returnUrl: encodeURI(state.url) } });
      return false;
    } else if (this.auth.currentUserValue()?.umail && this.auth.currentUserValue()?.umail.split('@nibo.com.br').length !== 2) {
      this.auth.logout();
      this.router.navigate(['login'], { queryParams: { returnUrl: encodeURI(state.url) } });
      return false;
    }
    return true;
  }
}
