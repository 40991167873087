<div class="login-container container-fluid bg-light d-flex flex-column align-items-center justify-content-center">
  <form *ngIf="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-signin text-center">
    <img class="mb-4" src="https://cdn.nibo.com.br/brand/logo/nb-logo-img-azpet.png" height="72">
    <h1 class="h3 mb-3 font-weight-normal fade-in">Ferramenta para Vendas</h1>
    <div *ngIf="errorMessage" class="alert alert-danger">
      {{errorMessage}}
    </div>
    <div class="input-group fade-in">
      <span class="input-group-text bg-white text-muted"><i class="fas fa-user"></i></span>
      <input formControlName="email" type="email" id="inputEmail" class="form-control" placeholder="user@nibo.com.br" required autofocus>
    </div>
    <div class="input-group mt-1 fade-in">
      <span class="input-group-text bg-white text-muted"><i class="fas fa-key"></i></span>
      <input formControlName="password" type="password" id="inputPassword" class="form-control" placeholder="******" required>
    </div>
    <button [disabled]="states.submitted || loginForm.invalid"  class="btn fade-in  btn-primary btn-block mt-3" type="submit"><span *ngIf="!states.submitted">Entrar</span> <i class="fas fa-spinner fa-spin" *ngIf="states.submitted"></i></button>
    <p class="mt-4 mb-3 text-muted">© Nibo - <a href="https://empresa.nibo.com.br" target="_blank">Cadastre-se</a> | <a href="https://empresa.nibo.com.br/User/LostPassword" target="_blank">Redefinir senha</a></p>
  </form>  
</div>
