import { ChangeDetectorRef, Component } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AzureBlobService } from '../../../../shared/services/azure/azure-blob.service';

@Component({
  selector: 'app-email-signature',
  templateUrl: './email-signature.component.html',
  styleUrl: './email-signature.component.scss'
})
export class EmailSignatureComponent {
  public emailSignatureForm = new FormGroup({
    email: new FormControl(''),
    name: new FormControl(''),
    role: new FormControl(''),
  });

  public photoPreview: string = 'https://picsum.photos/200/200';

  @ViewChild('emailSignature') emailSignatureElement!: ElementRef;

  constructor(private _toastr: ToastrService, private _blobService: AzureBlobService, private _changeDetector: ChangeDetectorRef) {
  }

  private _generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    })
  }

  public onPhotoChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file = target.files?.item(0);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = 200;
          canvas.height = 200;
          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.beginPath();
            ctx.arc(100, 100, 100, 0, Math.PI * 2, true);
            ctx.closePath();
            ctx.clip();

            ctx.drawImage(img, 0, 0, 200, 200);

            canvas.toBlob((blob) => {
              if (blob) {
                this._blobService.uploadBlob(blob, 'uploads', `${this._generateGUID()}.png`).subscribe((url) => {
                  this.photoPreview = url.split('?')[0];
                  this._changeDetector.detectChanges();
                });
              }
            }, 'image/png');
          }
        };
        img.src = e.target?.result as string;
      };
      
      reader.readAsDataURL(file);
    }
  }

  public copySignatureGmail(): void {
    const emailSignature = this.emailSignatureElement.nativeElement;
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(emailSignature);
    selection?.removeAllRanges();
    selection?.addRange(range);
    document.execCommand('copy');
    selection?.removeAllRanges();

    this._toastr.success('Assinatura copiada para a área de transferência');
  }

  public copySignatureHubspot(): void {
    const emailSignature = this.emailSignatureElement.nativeElement;
    const signatureHtml = emailSignature.outerHTML;
    const textarea = document.createElement('textarea');
    textarea.value = signatureHtml;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this._toastr.success('Assinatura copiada para a área de transferência');
  }
}
