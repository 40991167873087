<header>
  <nav class="navbar px-3 navbar-expand-lg navbar-light bg-light d-flex justify-content-between">
    <div class="ps-3">
      <a class="navbar-brand" routerLink="/">
        <img class="d-inline-block align-top mr-2" src="https://cdn.nibo.com.br/brand/logo/nb-logo-txt-azpet.png" height="20"  alt="">
      </a>
    </div>

    <div class="text-right" *ngIf="userInfo">
      <div class="dropdown user-dropdown">
        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-boundary="window" data-bs-toggle="dropdown">
          {{(userInfo.ufnm + ' ' + userInfo.ulnm)}}
        </button>
        <div class="w-100 dropdown-menu dropdown-menu-right">
          <a routerLink="" class="dropdown-item" (click)="logout()"><i class="fas fa-sign-out-alt mr-1"></i> Sair</a>
        </div>
      </div>
    </div>
  </nav>
</header>
