import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'hcr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public states: any;
  public loginForm!: FormGroup;
  public returnUrl!: string;
  public errorMessage!: string | null;
  constructor(
    private _authenticationService: AuthService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.states = { submitted: false };
  }

  ngOnInit(): void {
    this._authenticationService.logout();
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] ? decodeURI(<string>this._route.snapshot.queryParams['returnUrl']) : '/';

    this.loginForm = this._formBuilder.group({
      email: [''],
      password: [''],
    });
  }

  public onSubmit(): void {
    this.states.submitted = true;
    this.errorMessage = null;

    if (this.loginForm.invalid) {
      return;
    }

    this._authenticationService
      .authenticate(<string>this.loginForm.controls['email'].value, <string>this.loginForm.controls['password'].value)
      .pipe(first())
      .subscribe(
        () => {
          this._router.navigate([this.returnUrl]);
        },
        (error) => {
          console.log('Error no login:', error);
          this.states.submitted = false;
          this.errorMessage = typeof error === 'string' ? error : error['error']['error_description'] || 'Ops! Verifique suas informações';
        }
      );
  }
}
